import { usePathname } from 'next/navigation';
import { signIn } from 'next-auth/react';
import { env } from 'next-runtime-env';

import { ButtonCore, TypographyCore } from '~/components/core-components';

import { YandexLogo } from '../../../../../public/yandex-logo';

import styles from './yandex-login-button.module.css';

const DEV_HOST = 'testsympee.ru';
const serviceHost = env('NEXT_PUBLIC_HOST') || DEV_HOST;

export const YaButton = () => {
    const pathname = usePathname();

    return (
        <>
            <ButtonCore
                size='large'
                view='grey'
                className={styles['button']}
                onClick={() => {
                    signIn('yandex', { callbackUrl: `https://${serviceHost}${pathname}` });
                }}
            >
                <YandexLogo w={'24px'} h={'24px'} />
                <TypographyCore view='bold' size='s'>
                    Войти с Яндекс ID
                </TypographyCore>
            </ButtonCore>
        </>
    );
};
