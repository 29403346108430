import { createIcon } from '@chakra-ui/icons';

export const YandexLogo = createIcon({
    displayName: 'YandexLogo',
    viewBox: '0 0 24 24',
    path: (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clip-path='url(#clip0_5598_10125)'>
                <path
                    d='M12 23.9746C18.6139 23.9746 23.9755 18.6129 23.9755 11.999C23.9755 5.38508 18.6139 0.0234375 12 0.0234375C5.38605 0.0234375 0.0244141 5.38508 0.0244141 11.999C0.0244141 18.6129 5.38605 23.9746 12 23.9746Z'
                    fill='#FC3F1D'
                />
                <path
                    d='M16.3334 19.5221H13.7046V6.51993H12.5334C10.3868 6.51993 9.26233 7.59326 9.26233 9.19548C9.26233 11.0133 10.0379 11.8555 11.6423 12.9288L12.9646 13.8199L9.16456 19.5199H6.33789L9.75789 14.431C7.79122 13.0266 6.68456 11.6555 6.68456 9.34215C6.68456 6.45104 8.70011 4.48438 12.5157 4.48438H16.3157V19.5177H16.3334V19.5221Z'
                    fill='white'
                />
            </g>
            <defs>
                <clipPath id='clip0_5598_10125'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    ),
});
