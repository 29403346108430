import React, { useEffect, useState } from 'react';
import { Modal, ModalCloseButton, ModalContent, ModalOverlay } from '@chakra-ui/react';

import { getCookies } from '~/app/actions';

import { EmailScreen } from './email-screen';
import { PinScreen } from './pin-screen';

interface ProfileModalProps {
    onClose: () => void;
}
const TIMER_DURATION = 300;

export const ProfileModal = ({ onClose }: ProfileModalProps) => {
    const [openPinScreen, setOpenPinScreen] = useState(false);
    const [email, setEmail] = useState('');

    const [timer, setTimer] = useState(TIMER_DURATION);
    const [isRunning, setIsRunning] = useState<boolean>(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const [tokenSendError, setTokenSendError] = useState('');

    const handleCloseModal = () => {
        setOpenPinScreen(false);
        setEmail('');
        onClose();
    };

    const startTimer = () => {
        localStorage.setItem('timerStartTime', Date.now().toString());
        setTimer(TIMER_DURATION);
        setIsRunning(true);
    };

    useEffect(() => {
        getCookies('token-error')
            .then((item) => {
                if (item === 'true') {
                    setIsTokenError(true);
                } else {
                    setIsTokenError(false);
                }
            })
            .catch((err) => console.error(err));
    }, []);

    useEffect(() => {
        const savedStartTime = localStorage.getItem('timerStartTime');

        if (savedStartTime) {
            const elapsedTime = Math.floor((Date.now() - parseInt(savedStartTime, 10)) / 1000);
            const remainingTime = TIMER_DURATION - elapsedTime;

            if (remainingTime > 0) {
                setTimer(remainingTime);
                setIsRunning(true);
            } else {
                localStorage.removeItem('timerStartTime');
            }
        }
    }, []);

    useEffect(() => {
        if (!isRunning || timer <= 0) return;

        const timerLeft = setInterval(() => {
            setTimer((prev) => {
                if (prev <= 1) {
                    clearInterval(timerLeft);
                    setIsRunning(false);
                    localStorage.removeItem('timerStartTime');
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timerLeft);
    }, [isRunning]);

    return (
        <Modal isOpen={true} closeOnOverlayClick={false} returnFocusOnClose={false} onClose={handleCloseModal} autoFocus={false} isCentered={true}>
            <ModalOverlay />
            <ModalContent pt='40px' pl='40px' pr='40px' pb='40px' borderRadius='20px'>
                <ModalCloseButton color='rgba(207, 207, 207, 1)' onClick={handleCloseModal} />
                {openPinScreen ? (
                    <PinScreen
                        onClose={handleCloseModal}
                        setOpenPinScreen={setOpenPinScreen}
                        email={email}
                        isTokenError={isTokenError}
                        tokenSendError={tokenSendError}
                        setTokenSendError={setTokenSendError}
                        startTimer={startTimer}
                        timer={timer}
                    />
                ) : (
                    <EmailScreen
                        setOpenPinScreen={setOpenPinScreen}
                        setEmail={setEmail}
                        email={email}
                        isTokenError={isTokenError}
                        setTokenSendError={setTokenSendError}
                        startTimer={startTimer}
                    />
                )}
            </ModalContent>
        </Modal>
    );
};
