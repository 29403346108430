import { apiSlice } from '../rtk-query/api';

export interface City {
    id: number | null;
    name: string | null;
    lat: number | null;
    lon: number | null;
    image: string | null;
}
interface PositionItem {
    id: number;
    name: string;
    price: number;
    quantity: number;
    images: string[];
}

interface FullGiftInfo {
    id: string;
    status: string;
    created_at: string;
    description: string;
    link: string;
    total_price: number;
    positions: PositionItem[];
    validity_days?: number;
    receipt_url: null | string;
    expired_at: string;
    partner: {
        id: number;
        name: string;
        logo: string;
    };
}

export interface Gift {
    id: string;
    status: string;
    created_at: string;
    description: string;
    total_price: number;
    partner: {
        id: number;
        logo: string;
        name: string;
    };
    positions: PositionItem[];
}

export const userApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getCities: builder.query<{ data: { items: City[] } }, void>({
            query: () => `v1/cities`,
            transformErrorResponse: () => 'Возникла проблема при получении списка доступных городов. Попробуйте еще раз',
        }),
        auth: builder.query<{ data: string }, { email: string }>({
            query: (params) => ({ url: 'v1/user/auth', method: 'POST', body: params }),
            transformErrorResponse: (response) => {
                // @ts-expect-error типизировать data
                if (response.data?.code === 'SMP008') {
                    return 'Код уже был отправлен на указанную почту';
                }
                return 'Возникла проблема при попытке авторизоваться. Проверьте вводимую информацию или попробуйте позже';
            },
        }),
        yaAuth: builder.query<{ data: string }, { token: string }>({
            query: (params) => ({ url: 'v1/user/auth/ya', method: 'POST', body: params }),
            transformErrorResponse: () => 'Возникла проблема при попытке авторизоваться с помощью Яндекс',
        }),
        verify: builder.query<{ data: string }, { email: string; code: string }>({
            query: (params) => ({ url: 'v1/user/auth/verify', method: 'POST', body: params }),
            transformErrorResponse: () => 'Мы не смогли верифицировать Вас. Проверьте вводимую информацию или попробуйте позже',
        }),
        getUserInfo: builder.query<
            {
                data: {
                    phone: string;
                    name: string | null;
                    email: string | null;
                    birthday: string | null;
                };
            },
            void
        >({
            query: () => ({ url: `v1/user/me` }),
            transformErrorResponse: (response) => ({
                ...response,
                error: 'Произошла ошибка при получении Ваших данных. Возможно, время Вашей сессии истекло.',
            }),
        }),
        updateUser: builder.mutation<
            {
                data: {
                    phone: string;
                    name: string | null;
                    email: string | null;
                    birthday: string | null;
                };
            },
            { name?: string; email?: string; birthday?: string }
        >({
            query: (params) => ({ url: 'v1/user/info', method: 'PATCH', body: params }),
            transformErrorResponse: () => 'Возникла проблема при обновлении Ваших данных. Попробуйте еще раз',
        }),
        getGifts: builder.query<
            {
                data: {
                    items: Gift[];
                };
            },
            { $filter?: string }
        >({
            query: ({ $filter }) => `v1/user/gifts?${$filter}`,
            transformErrorResponse: () => 'Возникла проблема при получении списка Ваших подарков. Попробуйте еще раз',
        }),
        getGiftById: builder.query<
            {
                data: FullGiftInfo;
            },
            { id: string }
        >({
            query: ({ id }) => ({ url: `v1/user/gifts/${id}` }),
            transformErrorResponse: () => 'Возникла проблема при получении информации о выбранном подарке. Попробуйте еще раз',
        }),
        createOrder: builder.query<
            {
                data: string;
            },
            { positions: { product_id: number; quantity: number }[]; description: string; promocode_id?: string }
        >({
            query: (params) => ({ url: `v1/user/orders`, method: 'POST', body: params }),
            transformErrorResponse: () => 'Возникла проблема при создании заказа. Попробуйте еще раз',
        }),
    }),
});

export const {
    useGetCitiesQuery,
    useAuthQuery,
    useLazyAuthQuery,
    useVerifyQuery,
    useLazyVerifyQuery,
    useGetUserInfoQuery,
    useLazyGetUserInfoQuery,
    useUpdateUserMutation,

    useYaAuthQuery,

    useGetGiftByIdQuery,

    useGetGiftsQuery,
    useCreateOrderQuery,
    useLazyCreateOrderQuery,
} = userApi;
